import { axiosInstance } from '@config/axios-instance-v2';
import {
  AddAdminHotelDto,
  AdminAndTokenDto,
  AdminHotelListDto,
  AdminSignInDto,
  AdminUpdateProfile,
  ChangePasswordDto,
  DetailRoleDto,
  HotelPlacesAddDto,
  ListPermissionDto,
  ListRoleDto,
  PayloadCreateRoleDto,
} from '@libs/dto/admin';

const url = '/admin/settings/admins';
const urlAdmin = '/admin';

export const adminService = {
  async list({ page = 1, per_page = 10 }) {
    const response: AdminHotelListDto = await axiosInstance.get(`${url}`, {
      params: { page, per_page },
    });
    return response;
  },
  listByHotel(id: string, page = 1, per_page = 10) {
    return axiosInstance.get(`${url}/${id}/by-hotel`, {
      params: { page, per_page },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  signIn(credential: AdminSignInDto): Promise<AdminAndTokenDto> {
    return axiosInstance.post(`${urlAdmin}/auth/login-with-email`, credential);
  },
  async create(data: AddAdminHotelDto) {
    const response = await axiosInstance.post(`${urlAdmin}/auth/register`, data);
    return response;
  },
  async update(id: string, data: AdminUpdateProfile) {
    const response = await axiosInstance.put(`${url}/${id}`, data);
    return response;
  },
  async delete(id: string) {
    const response = await axiosInstance.delete(`${url}/${id}`);
    return response;
  },
  changePassword(data: ChangePasswordDto) {
    return axiosInstance.post(`${urlAdmin}/profile/change-password`, data);
  },
  getPlaces(hotel_id: number, page: number, per_page: number) {
    return axiosInstance.get(`${urlAdmin}/hotels/${hotel_id}/settings/places`, {
      params: { page, per_page },
    });
  },
  createPlaces(hotel_id: number, data: HotelPlacesAddDto) {
    return axiosInstance.post(`${urlAdmin}/hotels/${hotel_id}/settings/places`, data);
  },
  updatePlaces(hotel_id: number, id: string, data: HotelPlacesAddDto) {
    return axiosInstance.put(`${urlAdmin}/hotels/${hotel_id}/settings/places/${id}`, data);
  },
  deletePlaces(hotel_id: number, id: string) {
    return axiosInstance.delete(`${urlAdmin}/hotels/${hotel_id}/settings/places/${id}`);
  },
  async listRole({
    page = 1,
    per_page = 10,
    sort = '',
  }: {
    page: number;
    per_page: number;
    sort_by: string;
    sort: string;
  }) {
    const response: ListRoleDto = await axiosInstance.get(`${urlAdmin}/roles`, {
      params: {
        page,
        per_page,
        sort,
      },
    });
    return response;
  },
  async listPermission() {
    const response: ListPermissionDto = await axiosInstance.get(`${urlAdmin}/permissions`);
    return response;
  },
  async createRole(data: PayloadCreateRoleDto) {
    const response = await axiosInstance.post(`${urlAdmin}/roles`, data);
    return response;
  },
  async deleteRole(id: string) {
    const response = await axiosInstance.delete(`${urlAdmin}/roles/${id}`, {});
    return response;
  },
  async detailRole(id: string) {
    const response: DetailRoleDto = await axiosInstance.get(`${urlAdmin}/roles/${id}`, {});
    return response;
  },
  async updateRole(id: string, data: PayloadCreateRoleDto) {
    const response = await axiosInstance.put(`${urlAdmin}/roles/${id}`, data);
    return response;
  },
  async generateApiKey(id: string, expiryUnit: string, expiryDuration: number) {
    const response = await axiosInstance.post(`${url}/${id}/api-key/generate`, {
      expiryUnit,
      expiryDuration,
    });
    return response;
  },
};

export type AdminService = typeof adminService;
